import "./assets/main.scss";
import "flexlayout-react/style/light.css";
import AuthLayout from "./views/components/AuthLayout";
import { useRoutes } from "react-router-dom";
import { RouteType, getRoutes } from "./utils/routes";
import { ContainerProvider } from "./UI/context/ContainerProvider";

function App() {
  const navigation = getRoutes().map((x: RouteType) => {
    return {
      path: x.path,
      element: 
      x.isPrivate ? (
        <AuthLayout>
          <ContainerProvider>{x.element} </ContainerProvider>
        </AuthLayout>
      ) : (
        x.element
      ),
    };
  });

  const Router = useRoutes(navigation);
  return <>{Router}</>
}

export default App;
