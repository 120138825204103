
function clearLocalStorage() {
    localStorage.removeItem('bugsnag-anonymous-id')
    localStorage.removeItem('cesium-hasSeenNavHelp')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('flightdata')
    localStorage.removeItem('userData')
}

const LocalStorageUtil = {
    clearLocalStorage,
}

export default LocalStorageUtil