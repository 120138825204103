import {isAppRunningInBox} from "../../utils/environmentHelper";
import http, {pluginHttp} from "../../utils/request";
import {PluginOutputDto} from "../model/IPlugin";
import {getPluginInfo} from "./frappe/frappeService";

export async function getPlugin(url: string): Promise<string> {
    // Plugin indirmeyi 3 kere dene.
    // olmadigi dururumda exception firlat.
    for (let _try = 0; _try < 3; _try++) {
        try {
            const data = await pluginHttp.get(url, {
                headers: {"Content-Type": "application/json"},
            });
            return data.data;
        } catch (exp) {
            console.error(`Plugin Download Error: (${_try}): ${exp}`)
        }
    }

    throw "Plugin can't download!";
}

export const getPluginData = async (
    pluginId: string
): Promise<PluginOutputDto> => {
    const isInBox = isAppRunningInBox();
    if (isInBox) {
        // return await getMockPlugin(pluginId);
        return await getPluginInfo(pluginId);
    }
    const data = await http.get<any, PluginOutputDto>(`/plugins/${pluginId}`);
    return data;
};
