import axios from "axios";
// import { getToken } from "./tokenHelper";
import { notification } from "antd";
// import { readFrappePort } from "./appSettingsReader";
const qs = require("qs");

let baseUrl = process.env.REACT_APP_API_BASE_URL;

const httpFrappe = axios.create({
  baseURL: baseUrl,
  timeout: 300000,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    "Accept-Language": "en-US",
  },
  paramsSerializer: function(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

httpFrappe.interceptors.request.use(
  (config: any) => {
    if (!config.headers.Authorization && config.headers.Authorization !== "") {
      //  const headerToken = getToken();
      // if (headerToken) {
      //   config.headers.Authorization = `Bearer ${headerToken}`;
      // }
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

httpFrappe.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
      // return response.data.result;
    } else {
      notification.error({
        message: "Error",
        description: response.data.error || "Something went wrong...",
      });
    }
    return Promise.reject(response.data);
  },
  async (error) => {
    // if (error.response.status === 500) {
    //   notification.error({
    //     message: error.response.data.error.message || "Something went wrong...",
    //     description: error.response.data.error.details || "",
    //   });
    // }
    return Promise.reject(error);
  }
);

export const pluginHttp = axios.create();

export default httpFrappe;
